<template>
  <div class="form-section" :class="$store.state['App-UiColorSchema-api'].currentSchemaClasses">
<!--  <div class="title">Display Filter</div>-->
    <el-row class="box-section">
      <el-col :class="$langConfig.cssClass" :span="(!editMode)? 12 : 12">
        <dg-select-field
            :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
            :field_span="22"
            :label_span="24"
            :defaultValue="defaultFilterValue"
            @amUpdated="(p, val) => {filterUpdated()}"
            :options="[...dropdownAccountStatus, extraField]"
            :translateLabel='false'
            :translateLabels="false"
            field_mark=""
            label="Status"
            name="account_status"
            placeholder="status"
            rules=""></dg-select-field>
      </el-col>

      <el-col :class="$langConfig.cssClass" :span="(!editMode)? 12 : 12">
        <dg-select-field
            :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
            :field_span="22"
            :label_span="24"
            :defaultValue="defaultFilterValue"
            @amUpdated="(p, val) => {filterUpdated()}"
            :options="[...$store.state['App-User-api'].usersByProfiles.ADMIN, extraField]"
            :translateLabel='false'
            :translateLabels="false"
            field_mark=""
            label="User"
            name="person_in_charge"
            placeholder="Name"
            rules=""></dg-select-field>
      </el-col>
    </el-row>

    <el-row class="box-section">
      <el-col :class="$langConfig.cssClass" :span="(!editMode)? 12 : 12">
        <dg-select-field
            :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
            :field_span="22"
            :label_span="24"
            :defaultValue="defaultFilterValue"
            @amUpdated="(p, val) => {filterUpdated()}"
            :options="[...dropdownLegalStructure, extraField]"
            :translateLabel='false'
            :translateLabels="false"
            field_mark=""
            label="Legal Structure"
            name="legal_structure"
            placeholder=""
            rules="required"></dg-select-field>
      </el-col>

      <el-col :class="$langConfig.cssClass" :span="(!editMode)? 12 : 12">
        <dg-select-field
            :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
            :field_span="22"
            :label_span="24"
            :defaultValue="defaultFilterValue"
            @amUpdated="(p, val) => {filterUpdated()}"
            :options="[...$store.state['Business-Type-api'].dropDownList, extraField]"
            :translateLabel='false'
            :translateLabels="false"
            field_mark=""
            label="Business Type"
            name="business_type"
            placeholder=""
            rules="required"></dg-select-field>
      </el-col>
    </el-row>

    <el-row class="box-section">
      <el-col :class="$langConfig.cssClass" :span="(!editMode)? 12 : 12">
        <dg-select-field
            :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
            :defaultValue="defaultFilterValue"
            :field_span="22"
            :label_span="24"
            @amUpdated="(p, val) => {filterUpdated()}"
            :options="[...dropdownTaxReportingPeriods, extraField]"
            :translateLabel='false'
            :translateLabels="false"
            field_mark=""
            label="Accounting Type"
            name="account_type"
            placeholder=""
            rules="required"></dg-select-field>
      </el-col>

      <el-col :class="$langConfig.cssClass" :span="(!editMode)? 12 : 12">
        <dg-select-field
            :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
            :field_span="22"
            :label_span="24"
            :defaultValue="defaultFilterValue"
            @amUpdated="(p, val) => {filterUpdated()}"
            :options="[...dropdownProvinces, extraField]"
            :translateLabel='false'
            :translateLabels="false"
            field_mark=""
            label="Province"
            name="province"
            placeholder="Name"
            rules=""></dg-select-field>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import jsonTaxReportingPeriods from "@/json/dropdown/TaxReportingPeriods.json";
import jsonProvinces from "@/json/dropdown/Provinces.json";
import jsonLegalStructure from "@/json/dropdown/LegalStucture.json";
import jsonAccountStatus from "@/json/dropdown/AccountStatus.json";

export default {
  mixins: [$mixins['fieldController']],
  methods: {
    filterUpdated() {
      clearTimeout(this.filterTimeout)
      this.filterTimeout = setTimeout(() => {
        this.$emit('filterUpdated', this.dataModel)
      }, 200)
    }
  },
  props: {
    editMode: {
      required: true
    }
  },
  data () {
    return {
      dataModel: {
        filterTimeout: null,
        account_status: '-all-',
        person_in_charge: '-all-',
        legal_structure: '-all-',
        business_type: '-all-',
        account_type: '-all-',
        province: '-all-',
      },
      control: this,
      extraField: {label: 'ALL', value: '-all-'},
      defaultFilterValue: '-all-',
      dropdownAccountStatus: jsonAccountStatus,
      dropdownLegalStructure: jsonLegalStructure,
      dropdownProvinces: jsonProvinces,
      dropdownTaxReportingPeriods: jsonTaxReportingPeriods,
    }
  }
}
</script>
